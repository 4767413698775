import Landing from './Landing.js'
import Projects from './Projects.js';
import Timeline from './Timeline.js';
import Header from './Header.js';
import '../stylesheets/Home.css';

const Home = (props) => {
    return <>
        <div className="bg"
            style={{backgroundImage: `url('/images/blackmeshfullbg.png')`}}
        >   
            <Header {...props} />
            <div className="scroller">
                <Landing/>
                <Projects {...props}/>
                <Timeline />
            </div>
        </div>
    </>;
}

export default Home;