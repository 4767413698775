import '../stylesheets/ChemInitial.css';

const ChemInitial = (props) => {

    return <>
        <button className={'chem-btn primary-text ' + props.active + ' ' + (props.clickable ? 'clickable ' : ' ') + props.color} 
        onClick={() => {props.handler(props.idx)}}>{props.title}</button>
    </>
}

export default ChemInitial;