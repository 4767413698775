import '../stylesheets/Featured.css'
import ProjectCard from './ProjectCard.js'

const Featured = (props) => {

    return <>
        <section id="featured">
            <div className="section-bg" 
                >
                <div className="featured-wrapper">
                    <div className="featured-header">
                        <h2 className="featured-heading">Featured Projects </h2>
                    </div>
                    <div className="featured-container">
                        {props.f_projects?.map((e, idx) => <ProjectCard key={idx} {...e} />)}
                    </div>
                </div>
            </div>
        </section>
    </>;
}

export default Featured;