import { BrowserRouter, Route, Routes,} from 'react-router-dom';
// import Layout from './components/Layout';
import Home from './components/Home';
import './stylesheets/App.css';
import './stylesheets/ColourStyles.css'
import { useState } from 'react';

function App() {
  const [ colorTheme, setColorTheme ] = useState("purple");
  const props = {color: colorTheme, onColorChange: setColorTheme}

  return <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<Layout {...props}/>}> */}
        <Route index element={<Home {...props}/>}/>
      {/* </Route> */}
    </Routes>
  </BrowserRouter>;
}

export default App;
