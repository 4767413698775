import { useEffect, useState } from 'react';
import '../stylesheets/ProjectCard.css'
import TagCard from './TagCard';

const ProjectCard = (project) => {
    const [prodHide, setprodHide] = useState("");
    const [repoHide, setrepoHide] = useState("");

    useEffect(() => {
        if (project.link === "") {
            setprodHide("invisible");
        } else {
            setprodHide("");
        }
    }, [project.link])

    useEffect(() => {
        if (project.repo_link === "") {
            setrepoHide("invisible");
        } else {
            setrepoHide("");
        }
    }, [project.repo_link])

    return <>
        <div className={"proj-card-container shadow-bg"}>
            <div className="proj-text">
                <div className="proj-links">
                    <div className={"proj-chem-initials " + project.color}>
                        <h1>{project?.initials}</h1>
                    </div>
                    <h1>{project?.title}</h1>
                    <a className={project.color + " " + prodHide} href={project?.link}>Live</a>
                    <a className={project.color + " " + repoHide} href={project?.repo_link}>See Project</a>
                </div>
                <h2 className={"alt-text " + project?.color}>WHAT IT IS</h2>
                <p>{project?.description}</p>
                <h2 className={"alt-text " + project?.color}>TECHNOLOGIES USED</h2>
                {/* <p>{project?.experience}</p> */}
                <div className="project-tech">
                    {project?.tech?.map((item, index) => {
                        let currproject = {
                            title: item,
                            color: project?.color,
                        }
                        return <TagCard key={index} {...currproject} />
                    })}
                </div>
            </div>
        </div>
    </>;
}

export default ProjectCard;