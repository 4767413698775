import '../stylesheets/TagCard.css';

const TagCard = (props) => {

    return <>
        <div className={"tag-card " + props?.color}>
            <p className='tag-card-tag primary-text'>{props?.title}</p>
        </div>
    </>
}

export default TagCard;